:root {
    /* Set browser box model to behave more in-line with developers' expectations:
     * width and height include padding and border, and thus represent the box's
     * actual size on screen.
    */
    box-sizing: border-box;
}

*,
*::before,
*::after {
    /* Sometimes components have been developed with [content-box] box-sizing in mind,
     * and in these cases, we can manually apply [box-sizing: content-box] to
     * restore the default behaviour for that component and all its children.
     */
    box-sizing: inherit;
}
