/* Applied from https://gist.github.com/MoOx/9137295*/
button {
    font: inherit;
    line-height: normal;
    overflow: visible;
    width: auto;
    margin: 0;

    padding: 0;

    /* inherit font & color from ancestor*/
    color: inherit;
    border: none;

    /* Normalize [line-height]. Cannot be changed from [normal] in Firefox 4+. */
    background: transparent;

    /* Corrects font smoothing for webkit*/
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable [input] types in iOS*/
    -webkit-appearance: none;

    cursor: pointer;
}

/*Remove excess padding and border in Firefox 4+ */
button::-moz-focus-inner {
    padding: 0;
    border: 0;
}
